import React, {useEffect, useRef, useState} from 'react'
import {Button, Dropdown, Form, Modal} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {TeamMembersColumn} from "../../organizations/admin/teams/TeamMembersColumn";
import {TeamServicesColumn} from "../../organizations/admin/teams/TeamServicesColumn";
import FilterComponent from "../FilterComponent";
import {Link, useParams} from "react-router-dom";
import {faDownload, faEllipsisH, faFileUpload, faFolderPlus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TeamNewFileModal} from "./TeamNewFileModal";
import ModalDialog from "react-bootstrap/ModalDialog";
import Draggable from 'react-draggable';
import axios from "axios";
import {zynity_api} from "../../../config/external-routes";
import Swal from "sweetalert2";
import {useSelector} from "react-redux";
import {getCnAData} from "../../../store/user_organizations/teams/tools/connect_and_align";

export const TeamFilesModal = ({showCreateModal, handleCloseModal, DraggableModalDialog, setShowParentModal, setShowCurrentModal, addTeamFileToList, teamFilesModalMode}:any) => {
    const [showNewTeamFilesModal, setShowNewTeamFilesModal] = useState(false);
    const [showMoreSubItem, setShowMoreSubItem] = useState(false);
    const {organization_id, team_id} = useParams();

    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filterText, setFilterText] = React.useState(null);

    const [data, setData] = useState([]);

    const loadTeamFiles = async (page: number) => {
        setLoading(true)
        setCurrentPage(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/documents_by_team?page=${page}&limit=${perPage}${filter}&document_type=${teamFilesModalMode}`;
        await axios.get(`${url}`)
            .then((response) => {
                setLoading(false)
                setData(response.data.data)
                setTotalRows(response.data.total);
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `We have problems getting the files, please refresh the page and try again!`,
                })
                throw error;
            })
    }

    const handlePerRowsChange = async (newPerPage:number, page:number) => {
        setLoading(true);
        setCurrentPage(page);
        let filter = filterText !== null && filterText !== '' ? `&search_text=${filterText}` : '';
        let url = zynity_api.organization_team.replace('{organization_id}', organization_id).replace('{team_id}', team_id);
        url += `/documents_by_team?page=${page}&limit=${newPerPage}${filter}&document_type=${teamFilesModalMode}`;

        await axios.get(`${url}`)
            .then((response) => {
                setLoading(false)
                setData(response.data.data)
                setTotalRows(response.data.total);
                setPerPage(newPerPage);
                setLoading(false);
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `We have problems getting the files, please refresh the page and try again!`,
                })
                throw error;
            })
    };

    useEffect(() => {
        loadTeamFiles(1)
    }, [showCreateModal])

    useEffect(() => {
        if(filterText !== null) {
            const timeOutId = setTimeout(() => loadTeamFiles(1), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [filterText]);


    const closeModal = () => {
        handleCloseModal()
        setShowParentModal(true)
    }

    const handleSubMenuToggle = () => {
        setShowMoreSubItem(!showMoreSubItem);
    };

    const formatDate = (isoString: string): string => {
        const date = new Date(isoString);
        return new Intl.DateTimeFormat('en-US', {
            dateStyle: 'long', // Full month name and day
            timeStyle: 'short', // Hours and minutes
        }).format(date);
    };

    let column_name = 'File'
    if(teamFilesModalMode == 'link') {
        column_name = 'Link'
    }

    const columns = [
        {
            name: column_name,
            selector: (row: { TeamName: any; }) => (<>
                {
                    teamFilesModalMode == 'file' ?
                        <>{row.document.displayName}</> :
                        (
                            <a href={row.document.displayName} target='_blank'>
                                {row.document.displayName}
                            </a>
                        )
                }
                </>),
            maxWidth: '350px'
        },
        {
            name: 'Added at',
            selector: (row: { TeamName: any; }) => formatDate(row.created_at),
            maxWidth: '250px'
        },
        {
            name: '',
            cell: (row: any) => {
                return  (
                    <>
                        {
                            teamFilesModalMode == 'file' &&
                            <a href={`${zynity_api.global_download_files}?path=${row.document.path}&force_download=true&custom_name=${row.document.displayName}`}>
                                <button className="btn btn-teal mx-1">
                                    <FontAwesomeIcon className='clickable mt-1' style={{width: '27px', fontSize: '18px'}} icon={faDownload}/>
                                </button>
                            </a>
                        }
                        <button className="btn btn-teal mx-1"
                                onClick={() => {addTeamFileToList(row.id, row.document.path, row.document.displayName); closeModal();}}
                        >
                            {teamFilesModalMode == 'file' ? 'Add File' : 'Add Link'}
                        </button>
                    </>
                )
            },
            maxWidth: '150px'
        }
    ];

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            setFilterText('');
        };

        const handleFilter = (e: { target: { value: any; }; }) => {
            setFilterText(e.target.value)
        }

        return (
            <>
                <FilterComponent onFilter={handleFilter} onClear={handleClear} filterText={filterText} />
            </>
        );
    }, [filterText]);

    const handleCloseTeamFilesModal = () => {
        setShowNewTeamFilesModal(false)
        setShowCurrentModal(true)
        loadTeamFiles(1)
    };

    class DraggableModalNewTeamFilesDialog extends React.Component {
        render() {
            return <Draggable handle=".draggableHandler"><ModalDialog {...this.props} />
            </Draggable>
        }
    }

    const handleShowNewFileModal = () => {
        setShowNewTeamFilesModal(true)
        if(typeof setShowCurrentModal === 'function') {
            setShowCurrentModal(false)
        }
    }

    const handlePageChange = (page:number) => {
        loadTeamFiles(page);
    };

    return (
        <>
            <Modal backdrop="static" show={showCreateModal} onHide={closeModal} dialogClassName="custom-modal-width"
                   dialogAs={DraggableModalDialog}
            >
                <Modal.Header closeButton className="bg-teal draggableHandler d-flex justify-content-between" style={{ cursor: 'grab' }}>
                    <div className="d-flex w-100 justify-content-between align-items-center">
                        <Modal.Title>{teamFilesModalMode == 'file' ? 'Team Files' : 'Team Links'}</Modal.Title>
                        <button className="btn btn-outline-light ms-auto me-2" onClick={handleShowNewFileModal}>{teamFilesModalMode == 'file' ? 'Upload New File' : 'Add New Link'}</button>
                    </div>
                </Modal.Header>

                <Modal.Body className='row d-flex align-items-center'>
                    <div className="row">
                        <div className="col-12">
                            <DataTable
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                paginationPerPage={perPage}
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <TeamNewFileModal
                showCreateModal={showNewTeamFilesModal}
                handleCloseModal={handleCloseTeamFilesModal}
                handleCloseParentModal={closeModal}
                DraggableModalDialog={DraggableModalNewTeamFilesDialog}
                setShowParentModal={setShowCurrentModal}
                addTeamFileToList={addTeamFileToList}
                teamFilesModalMode={teamFilesModalMode}
            />
        </>
    )
}
