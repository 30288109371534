import React, {useState, useRef, useEffect} from 'react'
import {Button, Form, Modal} from "react-bootstrap";
import ReactQuill from "react-quill";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faFilePdf, faImage, faFile} from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import {constants} from "../../../config/constants";
import axios from "axios";
import {zynity_api} from "../../../config/external-routes";
import {useSelector} from "react-redux";
import Swal from "sweetalert2";
import {getCoachNotes} from "../../../store/coaches/teams/coach_notes";
import './headlineModal/HeadlineModal.scss'
import ModalDialog from 'react-bootstrap/ModalDialog';
import Draggable from 'react-draggable';
import {TeamFilesModal} from "./TeamFilesModal";

class DraggableModalDialog extends React.Component {
    render() {
        return <Draggable handle=".draggableHandler"><ModalDialog {...this.props} />
        </Draggable>
    }
}

export const HeadlineModal = ({showCreateModal, handleCloseModal, data, setData, submitHandled, settings, showEmailMembers = false, emailMemberList = [], selectedEmailMembers, setSelectedEmailMembers, disableSubmitBtn, setShowCreateModal}:any) => {
    const authenticated_user = useSelector(state => state.auth)
    const [showTeamFilesModal, setShowTeamFilesModal] = useState(false);
    const [teamFilesModalMode, setTeamFilesModalMode] = useState('file');
    const inputFileRef = useRef( null );
    let links = data.links;
    const uploads = data.uploads;
    const [uploading, setUploading] = useState(0);
    const [modalClass, setModalClass] = useState('custom-modal-width');
    const [modalMaximizeBtnText, setModalMaximizeBtnText] = useState('Maximize Window');

    const removeUploadHandle = (file_name:any) => {
        let arr = data.uploads.filter((upload:any) => {
            return upload.file_name !== file_name;
        });

        setData({
            ...data,
            'uploads': arr
        })
    }

    const removeLink = (key:any) => {
        let newLinks = links.filter((link:any, index:any) => index !== key);

        setData({
            ...data,
            'links': newLinks
        })
    }

    const addUploadHandle = (e:any) => {
        const formData = new FormData();
        let file = e.target.files[0];
        formData.append('file', file, file.name)
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${authenticated_user.access_token}`
            },
            onUploadProgress: function(progressEvent:any) {
                setUploading(Math.round( (progressEvent.loaded * 100) / progressEvent.total ));
            }
        }
        axios.post(zynity_api.global_upload_temp_files, formData, config).then(response => {
            setUploading(0);
            setData({
                ...data,
                'uploads': [...data.uploads, {
                    "id": null,
                    "file_name": response.data.file_name,
                    "display_name": response.data.display_name,
                }]
            })

        }).catch((e) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'We have problems uploading your file!',
            })
        })
    }

    const addTeamFileToList = (id:number, file_name:string, display_name:string) => {

        if(teamFilesModalMode == 'file') {
            setData({
                ...data,
                'uploads': [...data.uploads, {
                    "id": id,
                    "file_name": file_name,
                    "display_name": display_name,
                }]
            })
        } else {
            setData({
                ...data,
                'links': [...data.links, {
                    "id": id,
                    "link": display_name,
                }]
            })
        }
    }

    const addLinkInputDataHandle = (key:any, newVal:any) => {
        let newLinks = links.map((link:any, index:any) => {
            if(index === key) {
                return {...link, link: newVal};
            } else {
                return link;
            }
        })
        setData({
            ...data,
            'links': newLinks
        })
    }

    let labels = {
        title: settings.labels?.title === undefined ? 'Title' : settings.labels.title,
        description: settings.labels?.description === undefined ? 'Description' : settings.labels.description,
        links: settings.labels?.links === undefined ? 'Links' : settings.labels.links,
        uploads: settings.labels?.uploads === undefined ? 'Uploads' : settings.labels.uploads,
    }

    const handleCheckboxChange = (event:any) => {
        const checkboxId = parseInt(event.target.value);
        if (selectedEmailMembers.includes(checkboxId)) {
            setSelectedEmailMembers(selectedEmailMembers.filter((id: number) => id !== checkboxId));
        } else {
            setSelectedEmailMembers([...selectedEmailMembers, checkboxId]);
        }
    }

    const handleSelectAllChange = (event:any) => {
        if (event.target.checked) {
            const allIds = emailMemberList.map((data:any) => data.id);
            setSelectedEmailMembers(allIds);
        } else {
            setSelectedEmailMembers([]);
        }
    }

    const handleMaximize = () => {
        if(modalClass == 'custom-modal-width-xl') {
            setModalClass('custom-modal-width');
            setModalMaximizeBtnText('Maximize Window');
        } else {
            setModalClass('custom-modal-width-xl');
            setModalMaximizeBtnText('Minimize Window');
        }
    }

    const openNewFilePopup = () => {
        setShowTeamFilesModal(true)
        setTeamFilesModalMode('file')
        if(typeof setShowCreateModal === 'function') {
            setShowCreateModal(false)
        }
    }

    const openNewLinkPopup = () => {
        setShowTeamFilesModal(true)
        setTeamFilesModalMode('link')
        if(typeof setShowCreateModal === 'function') {
            setShowCreateModal(false)
        }
    }

    const handleCloseCreateCoachNoteModal = () => {
        setShowTeamFilesModal(false)
    };

    class DraggableModalTeamFilesDialog extends React.Component {
        render() {
            return <Draggable handle=".draggableHandler"><ModalDialog {...this.props} />
            </Draggable>
        }
    }

    console.log("links", links)

    return (
        <>
            <Modal show={showCreateModal} onHide={handleCloseModal} dialogClassName={modalClass}
                   dialogAs={DraggableModalDialog}
            >
                <Form>
                    <Modal.Header closeButton className='bg-teal draggableHandler modalWithoutCloseMargin' style={{cursor: 'grab'}}>
                        <Modal.Title>{settings.title}</Modal.Title>
                    <div className="ms-auto d-flex align-items-center">
                        <button type="button" className="btn btn-teal me-2" onClick={handleMaximize}>{modalMaximizeBtnText}</button>
                    </div>
                    </Modal.Header>
                    <Modal.Body className='row d-flex align-items-center'>
                        {
                            settings.is_comment !== true &&
                            <>
                                <div className="col-2 text-end">
                                    <Form.Label>{labels.title} *</Form.Label>
                                </div>

                                <div className="col-10">
                                    <Form.Control type="text" placeholder={labels.title}
                                                  value={data.title}
                                                  onChange={(e) => {setData({ ...data, title: e.target.value})}}
                                    />
                                </div>
                            </>
                        }

                        <div className="col-2 text-end mt-3">
                            <Form.Label>{labels.description}</Form.Label>
                        </div>
                        <div className="col-10 mt-3">
                            <ReactQuill
                                theme="snow"
                                placeholder='Description'
                                value={data.description}
                                onChange={(val) => {setData({ ...data, description: val})}}
                                modules={constants.quillDefaultModules}
                                formats={constants.quillDefaultFormats}
                            />
                        </div>

                        <div className="col-2 text-end mt-3">
                            <Form.Label>{labels.links}</Form.Label>
                        </div>
                        <div className="col-10">
                            {
                                links.map((link:any, key:any) => (
                                    <>
                                        <div className='d-flex justify-content-between'>
                                            <a href={link.link} target='_blank' className={'mt-4'}>{link.link}</a>
                                            <button type="button" style={{marginLeft: '10px'}} className="btn-close mt-4" onClick={() => {removeLink(key)}}></button>
                                        </div>
                                    </>
                                ))
                            }
                            <Button className='btn-teal mt-2' size="sm" onClick={openNewLinkPopup}>Add Link</Button>{' '}
                        </div>

                        <div className="col-2 text-end mt-3">
                            <Form.Label>{labels.uploads}</Form.Label>
                        </div>
                        <div className="col-10 mt-2">
                            <div className="row">
                                {
                                    uploads.length > 0 &&
                                    uploads.map((upload:any) => (
                                        <div className="col-12 mt-2">
                                            <div className='card'>
                                                <div className="card-body d-flex justify-content-between align-items-center p-2">
                                                        <span className='d-flex align-items-center'>
                                                            <FontAwesomeIcon icon={faFile} style={{color: "#007eda", height: '35', width: 'auto'}}/> <span className='mx-1'>{upload.display_name}</span>
                                                        </span>
                                                    <button type="button" className="btn-close" onClick={() => {removeUploadHandle(upload.file_name)}}></button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {
                                    uploading > 0 &&
                                    (
                                        <div className="col-12 mt-2 mb-2">
                                            <div className="progress">
                                                <div
                                                    className="progress-bar progress-bar-striped progress-bar-animated"
                                                    role="progressbar"
                                                    aria-valuenow="0"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                    style={{width: `${uploading}%`}}
                                                >
                                                    Uploading {uploading}%
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                <input type="file"
                                       style={{display: 'none'}}
                                       ref={inputFileRef}
                                       onChange={addUploadHandle}
                                />
                                <div className="col-12"><Button className='btn-teal mt-1' size="sm" onClick={openNewFilePopup}>
                                    {uploads.length > 0 ? 'Add More Files' : 'Add File'}</Button>
                                </div>
                            </div>
                        </div>

                        {
                            showEmailMembers &&
                            <>
                                <div className="col-2 text-end mt-3">
                                    <Form.Label>Email Members</Form.Label>
                                </div>
                                <div className="col-10">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={selectedEmailMembers.length === emailMemberList.length}
                                            onChange={handleSelectAllChange}
                                        />
                                        All
                                    </label>
                                    {emailMemberList.map((data:any) => (
                                        <label key={data.id} className='mx-1'>
                                            <input
                                                className='mx-1'
                                                type="checkbox"
                                                value={data.id}
                                                checked={selectedEmailMembers.includes(data.id)}
                                                onChange={handleCheckboxChange}
                                            />
                                            {data.KnownAs}
                                        </label>
                                    ))}
                                </div>
                            </>
                        }

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button className='btn-teal' onClick={submitHandled} disabled={disableSubmitBtn}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <TeamFilesModal
                showCreateModal={showTeamFilesModal}
                handleCloseModal={handleCloseCreateCoachNoteModal}
                DraggableModalDialog={DraggableModalTeamFilesDialog}
                setShowParentModal={setShowCreateModal}
                setShowCurrentModal={setShowTeamFilesModal}
                addTeamFileToList={addTeamFileToList}
                teamFilesModalMode={teamFilesModalMode}
            />
        </>
    )
}
